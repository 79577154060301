// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/App.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-h-2-h-fixts-js": () => import("./../../../src/pages/h2h-fixts.js" /* webpackChunkName: "component---src-pages-h-2-h-fixts-js" */),
  "component---src-pages-h-2-h-js": () => import("./../../../src/pages/h2h.js" /* webpackChunkName: "component---src-pages-h-2-h-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inner-circle-js": () => import("./../../../src/pages/inner-circle.js" /* webpackChunkName: "component---src-pages-inner-circle-js" */),
  "component---src-pages-league-list-js": () => import("./../../../src/pages/league-list.js" /* webpackChunkName: "component---src-pages-league-list-js" */),
  "component---src-pages-player-stats-js": () => import("./../../../src/pages/player-stats.js" /* webpackChunkName: "component---src-pages-player-stats-js" */),
  "component---src-pages-skyff-js": () => import("./../../../src/pages/skyff.js" /* webpackChunkName: "component---src-pages-skyff-js" */),
  "component---src-pages-stats-js": () => import("./../../../src/pages/stats.js" /* webpackChunkName: "component---src-pages-stats-js" */),
  "component---src-pages-team-stats-js": () => import("./../../../src/pages/team-stats.js" /* webpackChunkName: "component---src-pages-team-stats-js" */),
  "component---src-pages-ucl-js": () => import("./../../../src/pages/ucl.js" /* webpackChunkName: "component---src-pages-ucl-js" */),
  "component---src-pages-videprinter-js": () => import("./../../../src/pages/videprinter.js" /* webpackChunkName: "component---src-pages-videprinter-js" */),
  "component---src-pages-videprinter-old-js": () => import("./../../../src/pages/videprinter-old.js" /* webpackChunkName: "component---src-pages-videprinter-old-js" */),
  "component---src-templates-leagues-h-2-h-fixts-js": () => import("./../../../src/templates/leagues-h2h-fixts.js" /* webpackChunkName: "component---src-templates-leagues-h-2-h-fixts-js" */),
  "component---src-templates-leagues-page-h-2-h-js": () => import("./../../../src/templates/leagues-page-h2h.js" /* webpackChunkName: "component---src-templates-leagues-page-h-2-h-js" */),
  "component---src-templates-leagues-page-js": () => import("./../../../src/templates/leagues-page.js" /* webpackChunkName: "component---src-templates-leagues-page-js" */)
}

